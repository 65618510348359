<template>
  <div class="modal" @click.self="$emit('close')">
    <div class="modal__window">
      <div class="modal__window-content">
        <div class="modal__window-close" @click.stop="$emit('close')" />
        <slot />
      </div>
      <div class="modal__footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  components: {
    Footer: () => import('@/components/Footer.vue'),
  }
}
</script>

<style scoped lang="sass">
.modal
  position: fixed
  left: 0
  right: 0
  top: 0
  bottom: 0
  z-index: 3
  display: flex
  align-items: center
  justify-content: center

  &__window
    position: relative
    max-width: 1000px
    width: calc(100% - 50px)
    height: calc(100vh - 100px)
    margin: 0 auto

  &__window-content
    padding: 20px 20px 30px
    overflow-y: scroll
    overscroll-behavior: contain
    height: 100%
    max-height: 100%
    background: rgba(red, .1)
    border: 1px solid rgba(#fff, .2)
    position: relative

    @media (min-width: 600px)
      padding: 30px 40px 50px

  &__window-close
    cursor: pointer
    padding: 20px
    position: absolute
    top: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center

    &::before,
    &::after
      content: ''
      display: block
      background-color: #fff
      width: 3px
      height: 20px
      position: absolute

    &::before
      transform: rotateZ(-45deg)

    &::after
      transform: rotateZ(45deg)

  &__footer-container
    right: 0
    left: 0
    max-width: 640px
    margin: 0 auto
    padding: 10px 0
    display: flex
    align-items: center
    justify-content: center

    &-link
      color: inherit
      text-decoration: none
      margin: 0 10px

</style>
